.yarl__slide_captions_container {
    background: rgba(0, 0, 0, 0.0);
    left: 0;
    padding: 16px;
    position: absolute;
    right: 0;
    -webkit-transform: translateZ(0);
    mix-blend-mode: difference
}

.yarl__slide_title {
    font-size: 20px !important;
    font-weight: 400 !important;
    /* max-width: calc(100% - var(--yarl__toolbar_width, 0px)); */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.yarl__slide_title_container {
    top: var(--yarl__slide_title_container_top, 0);
}

.yarl__slide_description {
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 3; */
    color: var(--yarl__slide_description_color, #fff);
    -webkit-hyphens: auto;
    max-width: 33%;
    font-size: 14px;
    hyphens: auto;
    overflow: hidden;
    text-align: start;
}

.yarl__slide_description_container {
    mix-blend-mode: difference;
    background: red;
}

.yarl__navigation_prev{
    height: 100%;
    width: 50%;
    cursor: w-resize !important;

}

.yarl__navigation_prev > button {
    left: 0px;
    position: absolute;
    opacity: 0;
    z-index: 99;


}

.yarl__navigation_next{
    height: 100%;
    width: 50%;
    cursor: e-resize !important;
    z-index: 99;
}

.yarl__navigation_next > button {
    right: 0px;
    position: absolute;
    opacity: 0;
    text-align: right;

}


.yarl__toolbar{
    z-index: 999;
    cursor: pointer;
    opacity: .7;
    width: 30%;
}

.yarl__toolbar > Button{
    width: 100%;
    text-align: end;
    height: 100%;
    box-shadow: none;
    outline: 0;

}

.yarl__toolbar Button{
    cursor: pointer;
    box-shadow: none;
    outline: 0;
}

.yarl__toolbar:hover{
    opacity: 1;

}

.yarl__button > button{
    font-size: 20px;
    background: none;
    color:white;
    border: none;
    box-shadow: none !important;
    mix-blend-mode: difference;
}

.yarl__thumbnails_thumbnail{
    border: none;
}

.yarl__slide img{
    width: 86vw;
}

@media (max-width: 1200px) {

    .yarl__slide_description {
        max-width: 80%;
    }

    .yarl__navigation_prev > button {
        opacity: 1;
    }
    .yarl__navigation_next > button {
        opacity: 1;
        box-shadow: none !important;
    }

  }